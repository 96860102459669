

















































































































































import Vue from 'vue';
import popUpModal from '@/components/form/pop-up-modal.vue';
import FormSwitch from '@/components/form/form-switch.vue';
import { CASE_STATUS, WEBSITE_PUBLIC_URL } from '@/constant/constants';
import progressBar from '@/components/form/progress-bar.vue';
import errorMessage from '@/components/form/error-message.vue';

export default Vue.extend({
  name: 'logistics-insurance',
  components: {
    popUpModal,
    FormSwitch,
    progressBar,
    errorMessage,
  },
  props: ['assesment', 'insuranceKey', 'caseStatus', 'isAPIObserver', 'priceApiData', 'AMSAPISendStatus'],
  data() {
    return {
      processName: '',
      roadCarrier: false,
      forwardingActivities: false,
      watchman: false,
      transportInsuranceForClients: false,
      nextPath: '',
      nextPathError: false,
      key: 'LOGISTICS_INSURANCE',
      haveBussiness: false,
      haveTransport: false,
      remarks: '',
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
    };
  },
  methods: {
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    disableAllInputs() {
      const inputs = document.querySelectorAll('input');
      for (let i = 0; i < inputs.length; i += 1) {
        inputs[i].disabled = true;
      }

      const selects = document.querySelectorAll('select');
      for (let i = 0; i < selects.length; i += 1) {
        selects[i].disabled = true;
      }
    },
    toNextPatch() {
      if (this.roadCarrier) {
        this.nextPath = 'road-haulier';
      } else if (this.forwardingActivities) {
        this.nextPath = 'forwarding-activities';
      } else if (this.watchman) {
        this.nextPath = 'watchman';
      } else {
        this.nextPath = '';
      }
    },
    setValues(formData: any) {
      this.processName = formData.assesment.processName;
      this.roadCarrier = formData.progressBarData.roadCarrier;
      this.forwardingActivities = formData.progressBarData.forwardingActivities;
      this.watchman = formData.progressBarData.watchman;
      this.transportInsuranceForClients = formData.insuranceData.transportInsuranceForClients;
      this.haveBussiness = formData.assesment.haveBussiness;
      this.haveTransport = formData.assesment.haveTransport;
      this.remarks = formData.assesment.remarks || '';

      this.$emit(
        'progress-bar-change',
        {
          roadCarrier: this.roadCarrier,
          forwardingActivities: this.forwardingActivities,
          watchman: this.watchman,
        },
      );
    },
    scrollToFirstErrorElement() {
      const el = this.$el.getElementsByClassName('border-danger')[0];
      if (el) {
        el.scrollIntoView();
      }
    },
    onSubmit() {
      if (this.nextPath === '') {
        this.nextPathError = true;
        setTimeout(() => this.scrollToFirstErrorElement(), 500);
        return;
      }
      this.$emit(
        'progress-bar-change',
        {
          roadCarrier: this.roadCarrier,
          forwardingActivities: this.forwardingActivities,
          watchman: this.watchman,
        },
      );
      if (
        this.nextPath.length !== 0 &&
        this.processName.length !== 0
      ) {
        this.$emit(
          'risk-assesment-change',
          {
            remarks: this.remarks,
            processName: this.processName,
            haveTransport: this.haveTransport,
            haveBussiness: this.haveBussiness,
          },
          this.key,
        );
      }
      const data = {
        processName: this.processName,
        haveBussiness: this.haveBussiness,
        haveTransport: this.haveTransport,
        remarks: this.remarks,
        transportInsuranceForClients: this.transportInsuranceForClients,
      };
      if (this.nextPath === 'road-haulier') {
        this.$emit('data-changed', null, this.priceApiData, data, 'LOGISTICS_INSURANCE', this.nextPath);
      } else {
        this.$emit('data-changed', null, null, data, 'LOGISTICS_INSURANCE', this.nextPath);
      }
    },
  },
  computed: {
    Leitfadenpdf(): string {
      return `${WEBSITE_PUBLIC_URL}files/conditions/logistics/Transportversicherung-für-Auftraggeber.pdf`;
    },
    DeclarationManagerPDF(): string {
      return `${WEBSITE_PUBLIC_URL}files/conditions/logistics/Flyer-Declaration-Manager.pdf`;
    },
    isDisableAll(): boolean {
      if (this.isAPIObserver) {
        return true;
      }
      const result =
        this.caseStatus === CASE_STATUS.CONTRACT ||
        this.caseStatus === CASE_STATUS.SUGGESTION ||
        this.caseStatus === CASE_STATUS.CONTRACT_NO_SIGNATURE || this.AMSAPISendStatus !== null ||
        this.caseStatus === CASE_STATUS.OFFER_REQUEST_SEND;
      if (result) {
        this.disableAllInputs();
      }
      return result;
    },
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    if (this.$attrs.formData) {
      this.setValues(this.$attrs.formData);
    }
    this.toNextPatch();
  },
});
